import React from 'react';
import { Helmet } from 'react-helmet';
// import MethodDiagram from './assets/system.svg';
// import StaticResults from './assets/main_ours_results.svg';


import ninja_gif from './assets/examples/a_photograph_of_a_ninja.gif';
import zombie_gif from './assets/examples/Pumpkin_head_zombie_skinny_highly_detailed_photorealistic.gif';
import bread_gif from './assets/examples/A_DSLR_photo_of_a_freshly_baked_round_loaf_of_sourdough_bread.gif';
import knight_gif from './assets/examples/a_photograph_of_a_knight.gif';
import strawberry_gif from './assets/examples/A_ripe_strawberry.gif';
import icecream_gif from './assets/examples/An_ice_cream_sundae.gif';
import michdog_gif from './assets/examples/Michelangelo_style_statue_of_dog_reading_news_on_a_cellphone.gif';
import robobee_gif from './assets/examples/Robotic_bee_high_detail.gif';

function SDIgifExamples() {
  return (
    <div className="row justify-content-center text-start">
      <div className="col-10 col-md-5 my-2">
        <img src={bread_gif} className="img-fluid" alt="A DSLR photograph of a freshly baked round loaf of sourdough bread"/>
      </div>
      <div className="col-10 col-md-5 my-2">
        <img src={ninja_gif} className="img-fluid" alt="A photograph of a ninja" />
      </div>
      <div className="col-10 col-md-5 my-2">
        <img src={michdog_gif} className="img-fluid" alt="Michelangelo style statue of dog reading news on a cellphone"/>
      </div>
      <div className="col-10 col-md-5 my-2">
        <img src={robobee_gif} className="img-fluid" alt="Robotic bee high detail" />
      </div>
      <div className="col-10 col-md-5 my-2">
        <img src={zombie_gif} className="img-fluid" alt="Pumpkin head zombie, skinny, highly detailed" />
      </div>
      <div className="col-10 col-md-5 my-2">
        <img src={strawberry_gif} className="img-fluid" alt="A ripe strawberry" />
      </div>
      <div className="col-10 col-md-5 my-2">
        <img src={icecream_gif} className="img-fluid" alt="An ice cream sundae" />
      </div>
      <div className="col-10 col-md-5 my-2">
        <img src={knight_gif} className="img-fluid" alt="a photograph of a knight" />
      </div>
    </div>
  )
}

function SDIHeader() {
  return (
    <header className="sticky-top">
    <nav className="navbar px-4 navbar-expand-md navbar-light bg-light">
      <div className="container-xl text-dark fs-5">

          <div className="me-auto">
              <h4><span  role="img" aria-label="Emoji's about the project"> 🖼️🔗🛠️🌐 </span></h4>
          </div>

          <div className="d-none fw-lighter d-md-block">Score Distillation via Reparametrized DDIM</div>
  
          <div className="ms-auto">
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav">
                      <li className="nav-item">
                          <a className="nav-link active fw-light" href="/#">More Research</a>
                      </li>
                  </ul>
              </div>
          </div>

          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
          </button>

      </div>
    </nav>

  </header>
  );
}

function SDILinks() {
  return (
    <div className="column text-start mx-auto mt-4">
      <span className="link-block">
        <a href="https://arxiv.org/abs/2405.15891"
          className="external-link btn btn-dark rounded-pill">
          <span className="icon">
              <i className="ai ai-arxiv"></i>
          </span>
          <span>arXiv</span>
        </a>
      </span>
    </div>
  );
}

function SDI() {
  return (
    <div className="App">
        <Helmet>
          <title>Score Distillation via Reparametrized DDIM</title>
          <link rel="icon" type="image/png" href="/favicons/pages/sdi.png" />
        </Helmet>
        <SDIHeader/>
        <section id="cdi-main-page">
        <div className="container">
            <div className="row my-5">
                <div className="col-12">
                  <h1 className="mx-auto fw-bold text-start">
                    Score Distillation via Reparametrized DDIM
                  </h1>
                </div>
                <div className="col-12 col-md-10 ">
                  <h5 className="text-start">
                    <a className="active text-decoration-none" href="/#">Artem Lukoianov</a><sup>1</sup>,  &nbsp;
                    <a className="active text-decoration-none" href="https://scholar.google.com/citations?user=aP0OakUAAAAJ&hl=en">Haitz Sáez de Ocáriz Borde</a><sup>2</sup>, &nbsp;
                    <a className="active text-decoration-none" href="https://kgreenewald.github.io">Kristjan Greenewald</a><sup>3</sup>, &nbsp;
                    <a className="active text-decoration-none" href="https://scholar.google.com.br/citations?user=ow3r9ogAAAAJ&hl=en">Vitor Campagnolo Guizilini</a><sup>4</sup>, &nbsp;
                    <a className="active text-decoration-none" href="https://scholar.google.ch/citations?user=oLi7xJ0AAAAJ&hl=en">Timur Bagautdinov</a><sup>5</sup>, &nbsp;
                    <a className="active text-decoration-none" href="https://www.vincentsitzmann.com">Vincent Sitzmann</a><sup>1</sup>, &nbsp;
                    <a className="active text-decoration-none" href="https://people.csail.mit.edu/jsolomon/">Justin Solomon</a><sup>1</sup>
                  </h5>
                </div>
                <div className="col-8 col-md-8 text-start">
                  <span className="author-block"><sup>1</sup>Massachusetts Institute of Technology,</span>&nbsp;
                  <span className="author-block"><sup>2</sup>University of Oxford,</span>&nbsp;
                  <span className="author-block"><sup>1</sup>MIT-IBM Watson AI Lab, IBM Research,</span>&nbsp;
                  <span className="author-block"><sup>2</sup>Toyota Research Institute,</span>&nbsp;
                  <span className="author-block"><sup>1</sup>Meta Reality Labs Research</span>
                </div>
                <SDILinks/>
            </div>

            <SDIgifExamples/>

            <div className="row my-5">
                {/* <div className="col-7 my-7 text-center">
                  <img src={StaticResults} alt="Diagram of our method" />
                </div> */}
                <div className="col-12 my-5 text-end">
                    <h3 className="fw-semibold">Abstract</h3>
                    <p className='text-justify'>  
                      While 2D diffusion models generate realistic, high-detail images, 3D shape generation methods like Score Distillation Sampling (SDS)  built on these 2D diffusion models  produce cartoon-like, over-smoothed shapes. 
                      To help explain this discrepancy, we show that the image guidance used in Score Distillation can be understood as the velocity field of a 2D denoising generative process, up to the choice of a noise term. 
                      In particular, after a change of variables, SDS resembles a high-variance version of Denoising Diffusion Implicit Models (DDIM) with a differently-sampled noise term: SDS introduces noise i.i.d. randomly at each step, while DDIM infers it from the previous noise predictions. 
                      This excessive variance can lead to over-smoothing and unrealistic outputs. 
                      We show that a better noise approximation can be recovered by inverting DDIM in each SDS update step. 
                      This modification makes SDS's generative process for 2D images almost identical to DDIM. 
                      In 3D, it removes over-smoothing, preserves higher-frequency detail, and brings the generation quality closer to that of 2D samplers. 
                      Experimentally, our method achieves better or similar 3D generation quality compared to other state-of-the-art Score Distillation methods, all without training additional neural networks or multi-view supervision, and providing useful insights into relationship between 2D and 3D asset generation with diffusion models.
                    </p>
                </div>
            </div>

            <div className="row my-2 my-2">
              <div className="col-12 text-start">
                <h3 className="fw-semibold">BibTeX</h3>
                If you find this work useful in your research, please consider citing:
                <pre  className="bg-body-secondary"><code>{`
                  @misc{lukoianov2024score,
                      title={Score Distillation via Reparametrized DDIM}, 
                      author={Artem Lukoianov and Haitz Sáez de Ocáriz Borde and Kristjan Greenewald and Vitor Campagnolo Guizilini and Timur Bagautdinov and Vincent Sitzmann and Justin Solomon},
                      year={2024},
                      eprint={2405.15891},
                      archivePrefix={arXiv},
                      primaryClass={cs.CV}
                  }`}
                </code></pre>
              </div>
            </div>

            <div className="row my-2 my-2">
              <div className="col-12 text-center pt-6">
                  For any questions, please contact <a href="mailto:arteml@mit.edu">arteml@mit.edu</a>.
              </div>
            </div>

            </div>
        </section>
        {/* <SDIFooter/> */}
    </div>
  );
}

export default SDI;