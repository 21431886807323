import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route } from 'react-router-dom';
import './index.css';
import App from './App';
import CV from './assets/cv.pdf';
import * as serviceWorker from './serviceWorker';
import SDI from './papers/SDI/sdi';

import './custom.scss';
import "bootstrap-icons/font/bootstrap-icons.css";


ReactDOM.render(
    <BrowserRouter>
        <div>
            <Route exact path="/" component={App} />
            <Route path="/resume" component={() => { 
                window.location.href = CV; 
                return null;
            }} />
            <Route path="/sdi" component={SDI} />
        </div>
    </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
